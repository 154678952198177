.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  background-size: cover;
}

.carousel .thumb {
  width: 75px !important;
  height: 75px !important;
}

.carousel .slider-wrapper {
  position: relative;
}

.carousel .slider-wrapper:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.carousel .slider {
  position: absolute;
  top:      0;
  left:     0;
  bottom:   0;
  right:    0;
}

.carousel .slide div {
  height: 100%;
}

.carousel .slide img {
  height: 100%;
  object-fit: cover;
  background-size: cover;
}

.carousel .thumbs {
  padding: 0;
  display: flex;
  justify-content: center;
}
